import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { AccountPortal } from '@sections/index';
import ProfileService from '@services/profile-service/profile-service';
import { VehicleAttributes } from '@models/vehicle-attributes';
import { VehicleTabs } from '@sections/account-portal/components/vehicle-tabs/vehicle-tabs';
import AuthenticationService from '@services/authentication-service/authentication-service';
import { ActivityIndicator } from '@common/activity-indicator/activity-indicator';
import ErrorPage from '@sections/profile-error/error-page';
import { useWindowSize } from '@hooks/use-window-size';
import { CacheService } from '@services/cache-service/cache-service';
import ServerContext from '@contexts/serverContext';
import { useAppInformationContent } from '@sections/breadcrumbs/hook/use-breadcrumb-content';
import seoService from '@services/seo-service/seo-service';
import { TABLET_BREAKPOINT } from '@services/support-constants';
import { useMastheadContent } from '@sections/account-portal/hooks/use-masthead-content';
import { useVehicleCarouselContent } from '@sections/account-portal/components/vehicle-tabs/hooks/use-vehicle-carousel-content';
import { useAddVehicleContent } from '@sections/add-vehicle/hooks/use-add-vehicle';
import OrdersService, {
    OrderedVehiclesResponse,
} from '@services/orders-service/orders-service';
import {
    CONFIG_FOR_ORDERS_TO_FILTER_OUT,
    OrderedVehiclesItem,
} from '@models/orders-and-reservations';
import AppConfigurationService from '@services/app-configuration-service/app-configuration-service';
import './account-dashboard-view.scss';
import {
    DynamicMastheadContent,
    DynamicMastheadGreetingStyle,
    DynamicMastheadProperties,
    useDynamicMastheadContent,
} from '@sections/account-portal/components/vehicle-tabs/hooks/use-dynamic-masthead-content';
import {
    CANCELLED_ORDER_STATUSES,
    CX740_CARD,
    EU_COUNTRIES,
    ORDER_STATUS_CODES,
    VEHICLE_CARD_STORAGE_KEY,
} from '@/constants';
import {
    CongratsModalContent,
    useCongratsModalContent,
} from '@views/account-dashboard-view/hooks/use-congrats-modal-content';
import { ModalContextProps, useModalContext } from '@contexts/modalContext';
import { ORDER_TYPE } from '@sections/account-portal/components/vehicle-carousel/vehicle-order-card/vehicle-order-card';
import HttpService from '@services/http-service/http-service';
import { findPathByAlias } from '@routes/routesList';
import {
    buildCampaignIdQueryParameter,
    buildChargerAdapterStatusQueryParameter,
    buildConnectedServicesExtraQueryParams,
} from '@utils/router-util/router-util';
import { usePreferredDealerModalContent } from '@smart-tiles/smart-tile-preferred-dealer/hook/use-preferred-dealer-modal-content';
import { useConnectedServicesModalContent } from '@smart-tiles/smart-tile-connected-services/hook/use-connected-services-modal-content';
import DeletedVehicleService from '@services/deleted-vehicle-service/deleted-vehicle-service';
import OmsService from '@services/oms-service/oms-service';
import {
    filterForActiveItems,
    generateActiveOrderItems,
} from '@views/order-details-view/nvc-status-map';
import { AccessoriesCarouselItem } from '@sections/active-orders-carousel/active-orders-carousel';

export const AccountDashboardView = () => {
    const navigate = useNavigate();
    const { setContext, resetContext } = useModalContext();
    const [isLoading, setIsLoading] = useState(false);
    const appConfig: AppConfigurationService = new AppConfigurationService();
    const countryCode = appConfig.get2LetterCountryCode().toLowerCase();
    const dynamicMastheadContent: DynamicMastheadContent =
        useDynamicMastheadContent(appConfig.currentCountryCode);
    const [dynamicMastheadProperties, setDynamicMastheadProperties] =
        useState<DynamicMastheadProperties>({
            hasDynamicMasthead: false,
            orientation: null,
        });
    const preferredDealerModalContent = usePreferredDealerModalContent();
    const connectedServicesModalContent = useConnectedServicesModalContent();
    const [selectedDynamicMasthead, setSelectedDynamicMasthead] =
        useState<DynamicMastheadGreetingStyle>(null);
    const congratsModalContent: CongratsModalContent =
        useCongratsModalContent();
    const [vehiclesData, setVehiclesData] = useState<VehicleAttributes[]>([]);
    const [email, setEmail] = useState<string>();
    const size = useWindowSize();
    const [isMobile, setIsMobile] = useState(size?.width < 600);

    const [profileError, setProfileError] = useState({
        status: false,
        message: '',
    });
    const [vehiclesOnOrder, setVehiclesOnOrder] = useState<
        OrderedVehiclesItem[]
    >([]);
    const [vehiclesCount, setVehiclesCount] = useState<number>(0);
    const [ordersCount, setOrdersCount] = useState<number>(0);
    const [deletedVehicleState, setDeletedVehicleState] =
        useState<boolean>(false);
    const [hasCX740Reservation, setHasCX740Reservation] =
        useState<boolean>(false);
    const [hasCX740Order, setHasCX740Order] = useState<boolean>(false);
    const [displayAddVehicleModal, handleAddVehicleModal] =
        useState<boolean>(false);
    const [newVehicleState, setIsNewVehicleAdded] = useState<boolean>(false);
    const [ordersRequestCompleted, setOrdersRequestCompleted] =
        useState<boolean>(false);
    const [profileRequestCompleted, setProfileRequestCompleted] =
        useState<boolean>(false);
    const [userGuid, setUserGuid] = useState<string>(null);
    const mastheadContent = useMastheadContent();
    const vehicleCarouselContent = useVehicleCarouselContent();
    const addVehicleContent = useAddVehicleContent();
    const appInformationContent = useAppInformationContent();
    const authenticationService = new AuthenticationService();
    const profileService = new ProfileService();
    const cacheService = new CacheService();
    const httpService = HttpService;
    const ordersService: OrdersService = new OrdersService(httpService);
    const deletedVehicleService = new DeletedVehicleService(httpService);
    const [currentVehiclesCount, setCurrentVehiclesCount] =
        useState<number>(null);
    const queryParams = new URLSearchParams(useLocation().search);
    const hasConnectedServicesParameter: string =
        queryParams.get('connectedservices') || undefined;
    const hasAddVehicleParameter = queryParams.get('addvehicle') || undefined;
    const hasPreferredDealerParameter =
        queryParams.get('preferredDealer') || undefined;
    const chargerAdapterStatus = queryParams.get('keyName') || undefined;
    const [activeOrders, setActiveOrders] =
        useState<AccessoriesCarouselItem[]>(null);
    const [workingOrders, setWorkingOrders] =
        useState<AccessoriesCarouselItem[]>(null);
    const serverContext = useContext(ServerContext);
    const deliveredStatusCode: string[] =
        ORDER_STATUS_CODES[
            EU_COUNTRIES.includes(countryCode) ? 'eu' : countryCode
        ]?.delivered;
    const isCompletedAndDelivered = (order: OrderedVehiclesItem): boolean => {
        return (
            EU_COUNTRIES.includes(countryCode) &&
            order.status === ORDER_TYPE.COMPLETED
        );
    };
    const [deliveredVehicles, setDeliveredVehicles] = useState<
        OrderedVehiclesItem[]
    >([]);
    authenticationService.updateState(findPathByAlias('AccountDashboardView'));

    seoService.set(
        {
            pageTitle: appInformationContent?.accountDashboardPageTitle,
            applicationName: appInformationContent?.applicationName,
            pageDescription:
                appInformationContent?.accountDashboardPageDescription,
        },
        serverContext
    );

    const congratsModalProps: ModalContextProps = {
        modalType: {
            name: 'congrats-modal',
            primaryButtonLabel: congratsModalContent?.primaryButtonLabel,
            primaryButtonAriaLabel:
                congratsModalContent?.primaryButtonAriaLabel,
            closeButtonAriaLabel: congratsModalContent?.closeButtonAriaLabel,
            role: 'dialog',
            onPrimaryButtonClick: () => {
                closeCongratsModal();
                resetContext();
            },
            onAfterClose: () => {
                closeCongratsModal();
                resetContext();
            },
            children: (
                <>
                    <h2 className="fmc-type--heading3 fds-color__text--primary">
                        {congratsModalContent?.headerText}
                    </h2>

                    <div
                        className="congrats-modal-body fmc-type--body1 fds-color__text--gray3"
                        dangerouslySetInnerHTML={{
                            __html: congratsModalContent?.bodyText,
                        }}
                    ></div>
                </>
            ),
        },
    };

    const addToGarageIfDelivered = async (
        order: OrderedVehiclesItem
    ): Promise<void> => {
        if (
            (deliveredStatusCode &&
                deliveredStatusCode.includes(
                    order.vehicleStatusCode?.toUpperCase()
                ) &&
                order.status !== ORDER_TYPE.ORDERED) ||
            isCompletedAndDelivered(order)
        ) {
            const deletedVehicles =
                await deletedVehicleService.getDeletedVehicles();
            if (
                deletedVehicles?.deletedVehicles &&
                !deletedVehicles.deletedVehicles?.some(
                    (deletedVehicle) => deletedVehicle.vin === order.vin
                )
            ) {
                setDeliveredVehicles((deliveredVehicles) => [
                    ...deliveredVehicles,
                    order,
                ]);
            }
        }
    };

    const isValidOrder = (order: OrderedVehiclesItem): boolean => {
        return (
            !CANCELLED_ORDER_STATUSES.includes(order.status?.toUpperCase()) &&
            !CANCELLED_ORDER_STATUSES.includes(
                order.vehicleStatusCode?.toUpperCase()
            ) &&
            !deliveredStatusCode?.includes(
                order.vehicleStatusCode?.toUpperCase()
            ) &&
            !order.showroomJourneyType &&
            order.status !== ORDER_TYPE.COMPLETED
        );
    };

    const isCX740 = (order: OrderedVehiclesItem): boolean => {
        const isCX740Reservation =
            order.orderType?.toUpperCase() === ORDER_TYPE.RESERVATION &&
            order.readyToOrder === false &&
            order.model === CX740_CARD;
        const isCX740Order =
            order.orderType.toUpperCase() === ORDER_TYPE.ORDER &&
            order.status === ORDER_TYPE.ORDERED &&
            order.readyToOrder === false &&
            order.model === CX740_CARD;
        const isContractRequestedCX740Order =
            order.orderType.toUpperCase() === ORDER_TYPE.ORDER &&
            order.status === ORDER_TYPE.CONTRACT_REQUESTED &&
            order.readyToOrder === false &&
            order.model === CX740_CARD;

        if (isCX740Order || isContractRequestedCX740Order) {
            setHasCX740Order(true);
        } else if (isCX740Reservation) {
            setHasCX740Reservation(true);
        }

        return (
            isCX740Reservation || isCX740Order || isContractRequestedCX740Order
        );
    };

    const filterOrdersByAuthoring = (
        orders: OrderedVehiclesItem[]
    ): OrderedVehiclesItem[] => {
        if (vehicleCarouselContent) {
            const {
                eComVehicleCardOrderTitle,
                eComVehicleCardReservationTitle,
            } = vehicleCarouselContent;

            if (eComVehicleCardOrderTitle && eComVehicleCardReservationTitle) {
                return orders.filter((order) => isValidOrder(order));
            } else if (eComVehicleCardOrderTitle) {
                return orders.filter(
                    (order) =>
                        isValidOrder(order) &&
                        order.orderType?.toUpperCase() === ORDER_TYPE.ORDER
                );
            } else if (eComVehicleCardReservationTitle) {
                return orders.filter(
                    (order) =>
                        isValidOrder(order) &&
                        order.orderType?.toUpperCase() ===
                            ORDER_TYPE.RESERVATION
                );
            }
        }
    };

    const getAndSetProfileVehiclesData = () => {
        cacheService.evictProfileServiceCache();
        authenticationService
            .onIsAuthenticated()
            .then((isAuthenticated: boolean) => {
                if (isAuthenticated) {
                    profileService
                        .request()
                        .then((profile) => {
                            if (profile) {
                                setEmail(profile.profile.email);
                                setUserGuid(
                                    profile.profile.userGuid.toUpperCase()
                                );

                                if (profile.vehicles.length > 0) {
                                    setVehiclesCount(profile.vehicles.length);
                                    setCurrentVehiclesCount(
                                        profile.vehicles.length
                                    );
                                    const sortedVehicleProfiles =
                                        profile.vehicles;
                                    sortedVehicleProfiles.sort(
                                        (vehicle1, vehicle2) =>
                                            parseInt(vehicle2.modelYear!, 10) -
                                            parseInt(vehicle1.modelYear!, 10)
                                    );
                                    setVehiclesData(
                                        sortedVehicleProfiles.map((vehicle) => {
                                            return {
                                                year: parseInt(
                                                    vehicle.modelYear,
                                                    10
                                                ),
                                                make: vehicle.make,
                                                model: vehicle.modelName,
                                                vin: vehicle.vin,
                                                preferredDealer:
                                                    vehicle.preferredDealer,
                                                nickName: vehicle.nickName,
                                                ownerState: vehicle.ownerState,
                                            };
                                        })
                                    );
                                } else {
                                    setCurrentVehiclesCount(0);
                                }
                                setProfileRequestCompleted(true);
                            }
                        })
                        .catch((err) => {
                            cacheService.evictProfileServiceCache();
                            setProfileError({
                                status: true,
                                message: err.message,
                            });
                        });
                } else {
                    cacheService.evictProfileServiceCache();
                    authenticationService.login();
                }
            });
    };

    useEffect(() => {
        getAndSetProfileVehiclesData();
    }, []);

    useEffect(() => {
        userGuid &&
            authenticationService
                .onIsAuthenticated()
                .then((isAuthenticated: boolean) => {
                    if (isAuthenticated) {
                        if (
                            vehicleCarouselContent &&
                            (vehicleCarouselContent.eComVehicleCardOrderTitle ||
                                vehicleCarouselContent.eComVehicleCardReservationTitle)
                        ) {
                            ordersService
                                .getOrderedVehicles(userGuid)
                                .then((response: OrderedVehiclesResponse) => {
                                    if (response.orders.length) {
                                        const selectedUser =
                                            CONFIG_FOR_ORDERS_TO_FILTER_OUT.find(
                                                (order) =>
                                                    order.userGuid === userGuid
                                            );
                                        let ordersResponse: OrderedVehiclesItem[];

                                        if (selectedUser) {
                                            ordersResponse =
                                                response.orders.filter(
                                                    (order) =>
                                                        order.vin !==
                                                        selectedUser.vin
                                                );
                                        } else {
                                            ordersResponse = response.orders;
                                        }

                                        ordersResponse.forEach((order) => {
                                            addToGarageIfDelivered(order);
                                        });
                                        const ordersFilteredByAuthoring: OrderedVehiclesItem[] =
                                            filterOrdersByAuthoring(
                                                ordersResponse
                                            );
                                        ordersResponse.forEach((order) =>
                                            isCX740(order)
                                        );
                                        setVehiclesOnOrder(
                                            ordersFilteredByAuthoring
                                        );
                                        setOrdersCount(
                                            ordersFilteredByAuthoring.length
                                        );
                                    }
                                })
                                .catch((e) => {
                                    throw new Error(e);
                                })
                                .finally(() => setOrdersRequestCompleted(true));
                        } else {
                            setOrdersRequestCompleted(true);
                        }
                    }
                });
    }, [userGuid]);

    useEffect(() => {
        if (email && vehicleCarouselContent?.activeOrdersHeader) {
            const omsService = new OmsService(httpService);

            omsService.getOrderHistory(email, '1', '10').then((response) => {
                if (response?.orders?.length) {
                    const workingOrders: AccessoriesCarouselItem[] =
                        generateActiveOrderItems(response.orders);

                    setWorkingOrders(workingOrders);
                    setActiveOrders(filterForActiveItems(workingOrders));
                }
            });
        }
    }, [email]);

    useEffect(() => {
        if (
            ordersRequestCompleted &&
            profileRequestCompleted &&
            deliveredVehicles.length > 0
        ) {
            addDeliveredVehiclesToGarage();
        }
    }, [ordersRequestCompleted, profileRequestCompleted, deliveredVehicles]);

    const addDeliveredVehiclesToGarage = () => {
        const garageVehiclesVinArray = vehiclesData.map(
            (vehicle) => vehicle.vin
        );

        const deliveredVehiclesNotInGarage = deliveredVehicles.filter(
            (deliveredVehicle) =>
                !garageVehiclesVinArray.includes(deliveredVehicle.vin)
        );

        const addVehiclePromises = [];
        for (const deliveredVehicle of deliveredVehiclesNotInGarage) {
            //add VIN(s) to profile (garage)
            addVehiclePromises.push(
                profileService.addVehicle(deliveredVehicle.vin, '')
            );
        }
        Promise.all(addVehiclePromises).then((promises) => {
            if (promises.some((response) => response.status === 200)) {
                getAndSetProfileVehiclesData();
                setIsNewVehicleAdded(true);
                showDeliveredVehicleCongratsModal();
            }
        });
    };

    const showDeliveredVehicleCongratsModal = (): void => {
        if (congratsModalContent) setContext(congratsModalProps);
    };

    const closeCongratsModal = (): void => {
        resetContext();
    };

    const handleConnectedServiceView = (vin: string): void => {
        navigate({
            pathname: findPathByAlias('ConnectedServicesView'),
            search: `?vin=${vin}${buildCampaignIdQueryParameter()}${buildChargerAdapterStatusQueryParameter(
                !!chargerAdapterStatus
            )}${buildConnectedServicesExtraQueryParams(queryParams)}`,
        });
    };

    const handlePreferredDealerView = (): void => {
        sessionStorage.setItem(
            VEHICLE_CARD_STORAGE_KEY,
            JSON.stringify({
                vin: vehiclesData[0]?.vin,
                model: `${vehiclesData[0]?.year} ${vehiclesData[0]?.model}`,
                preferredDealerCode: vehiclesData[0]?.preferredDealer,
            })
        );
        navigate({
            pathname: findPathByAlias('PreferredDealerView'),
        });
    };

    useEffect(() => {
        if (!deletedVehicleState) {
            if (
                window.location.href.includes('connectedservices') &&
                vehiclesData.length === 1
            ) {
                handleConnectedServiceView(vehiclesData[0]?.vin);
            } else if (
                window.location.href.includes('preferredDealer') &&
                vehiclesData.length === 1
            ) {
                handlePreferredDealerView();
            }
            if (
                !newVehicleState &&
                (hasAddVehicleParameter ||
                    ((hasPreferredDealerParameter ||
                        hasConnectedServicesParameter) &&
                        currentVehiclesCount === 0))
            ) {
                handleAddVehicleModal(true);
            }
        }
    }, [vehiclesData]);

    useEffect(() => {
        const isMobileView = size.width < TABLET_BREAKPOINT;
        setIsMobile(isMobileView);
    }, [size.width]);

    useEffect(() => {
        if (dynamicMastheadContent) {
            const hasDynamicMastheadAuthored =
                !!dynamicMastheadContent.options.length;

            const defaultMasthead: DynamicMastheadGreetingStyle =
                dynamicMastheadContent.options?.find(
                    (option) => option.key === 'default'
                );
            const hasReservedCX740Masthead: DynamicMastheadGreetingStyle =
                dynamicMastheadContent.options?.find(
                    (option) => option.key === 'hasReservedCX740'
                );
            const hasOrderedCX740Masthead: DynamicMastheadGreetingStyle =
                dynamicMastheadContent.options?.find(
                    (option) => option.key === 'hasOrderedCX740'
                );

            if (hasCX740Order && hasOrderedCX740Masthead) {
                setSelectedDynamicMasthead(hasOrderedCX740Masthead);
                setDynamicMastheadProperties({
                    hasDynamicMasthead: hasDynamicMastheadAuthored,
                    orientation: hasOrderedCX740Masthead.orientation,
                });
            } else if (hasCX740Reservation && hasReservedCX740Masthead) {
                setSelectedDynamicMasthead(hasReservedCX740Masthead);
                setDynamicMastheadProperties({
                    hasDynamicMasthead: hasDynamicMastheadAuthored,
                    orientation: hasReservedCX740Masthead.orientation,
                });
            } else {
                setSelectedDynamicMasthead(defaultMasthead);
                setDynamicMastheadProperties({
                    hasDynamicMasthead: hasDynamicMastheadAuthored,
                    orientation: defaultMasthead.orientation,
                });
            }
        }
    }, [dynamicMastheadContent, hasCX740Reservation, hasCX740Order]);

    return (
        <>
            {isLoading && (
                <ActivityIndicator
                    className={'fds-activity-indicator__center'}
                />
            )}
            {email &&
            size.width &&
            mastheadContent &&
            vehicleCarouselContent ? (
                <AccountPortal
                    page="DASHBOARD"
                    hasVehicles={vehiclesCount > 0}
                    updateAddVehicleStatusWithVin={(vin: string) => {
                        // by updating this status, updated hasVehicles flag will be passed by prop drilling to manage the connected service smart tile dynamic button
                        setIsNewVehicleAdded(true);
                        handleConnectedServiceView(vin);
                    }}
                    fromConnectedServices={!!hasConnectedServicesParameter}
                    fromPreferredDealer={!!hasPreferredDealerParameter}
                    preferredDealerModalContent={preferredDealerModalContent}
                    connectedServicesModalContent={
                        connectedServicesModalContent
                    }
                    mastheadContent={mastheadContent}
                    dynamicMastheadContent={selectedDynamicMasthead}
                    dynamicMastheadProperties={dynamicMastheadProperties}
                    vehicleCarouselContent={vehicleCarouselContent}
                    addVehicleContent={addVehicleContent}
                    openAddVehicleModal={displayAddVehicleModal}
                    vehiclesCount={vehiclesCount}
                    ordersCount={ordersCount}
                    vehiclesOnOrder={vehiclesOnOrder}
                    setIsLoading={setIsLoading}
                    currentVehiclesCount={currentVehiclesCount}
                    setCurrentVehiclesCount={setCurrentVehiclesCount}
                    garageVehicles={vehiclesData}
                    addVehicleFromSmartTile={displayAddVehicleModal}
                    selectedDynamicMasthead={selectedDynamicMasthead}
                    setDynamicMastheadProperties={setDynamicMastheadProperties}
                    setSelectedDynamicMasthead={setSelectedDynamicMasthead}
                    handleAddVehicleModal={handleAddVehicleModal}
                    setIsNewVehicleAdded={setIsNewVehicleAdded}
                    showOrderHistoryImageCard={
                        workingOrders?.length > 0 &&
                        (!activeOrders || activeOrders?.length == 0)
                    }
                    topMasthead={
                        <VehicleTabs
                            redirectToDashboard={false}
                            vehiclesCount={(count) => {
                                setVehiclesCount(count);
                            }}
                            isNewVehicleAdded={newVehicleState}
                            setIsNewVehicleAdded={setIsNewVehicleAdded}
                            openAddVehicleModal={displayAddVehicleModal}
                            addVehicleContent={addVehicleContent}
                            dynamicMastheadProperties={
                                dynamicMastheadProperties
                            }
                            setDynamicMastheadProperties={
                                setDynamicMastheadProperties
                            }
                            selectedDynamicMasthead={selectedDynamicMasthead}
                            setSelectedDynamicMasthead={
                                setSelectedDynamicMasthead
                            }
                            isMobile={isMobile}
                            setIsLoading={setIsLoading}
                            fromAddVehicleParameter={!!hasAddVehicleParameter}
                            fromConnectedServices={
                                !!hasConnectedServicesParameter
                            }
                            currentVehiclesCount={currentVehiclesCount}
                            setCurrentVehiclesCount={setCurrentVehiclesCount}
                            vehiclesOnOrder={vehiclesOnOrder}
                            vehiclesData={vehiclesData}
                            setVehiclesData={setVehiclesData}
                            deletedVehicleState={deletedVehicleState}
                            setDeletedVehicleState={setDeletedVehicleState}
                            deliveredVehicles={deliveredVehicles}
                            vehicleCarouselContent={vehicleCarouselContent}
                            mastheadContent={mastheadContent}
                            activeOrders={activeOrders}
                        />
                    }
                    isMobile={isMobile}
                />
            ) : profileError.status ? (
                <ErrorPage />
            ) : (
                <ActivityIndicator className={'full-height'} />
            )}
        </>
    );
};
